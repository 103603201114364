import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Wrapper from '../components/Wrapper'
import Img from 'gatsby-image'
import styled from 'styled-components'
import YouMayAlsoLike from '../molecules/YouMayAlsoLike'
import {
 breakpoints,
 tychoGray,
 tychoNavy,
 typoLightGray,
 maxWidth,
 borderGray
} from '../utils/theme'
import BreadcrumbsTycho from '../molecules/BreadcrumbsTycho'
import ConfiguratorWrapper from '../molecules/SecondThirdWrapper/ConfiguratorWrapper'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './swiper-custom-styles.css'
import Sku from '../molecules/Sku'
import AnchorLink from '../molecules/AnchorLink'
import TopIcon from '../atoms/TopIcon'
import Content from '../molecules/ProductInfoSections/Content'
import SectionWrapper from '../molecules/ProductInfoSections/SectionWrapper'
import Section from '../molecules/ProductInfoSections/Section'
import Line from '../molecules/ProductInfoSections/Line'
import SectionTitle from '../molecules/ProductInfoSections/SectionTitle'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import slugify from 'slugify'
import { useLocation } from '@reach/router'

const MainProductPanel = styled.div`
 display: inline-flex;
 width: 100%;
 margin-top: 50px;

 .ConfiguratorWrapper {
  width: 516px;
  padding-left: 24px;
 }

 @media (max-width: ${breakpoints.desktop + 'px'}) {
  .ConfiguratorWrapper {
   width: 440px;
   margin-bottom: 32px;
  }
 }

 @media (max-width: ${breakpoints.tablet + 'px'}) {
  flex-direction: column;
  margin-top: 0;

  .ConfiguratorWrapper {
   padding-left: 0;
   width: 100%;
   max-width: 660px;
   margin-top: 18px;

   .sku {
    display: none;
   }
  }
 }

 .text {
  display: block;
  padding: 16px 0;
  font-size: 13px;
  line-height: 1.28571428571;
  color: ${tychoGray};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
 }
`

const SwiperWrapper = styled.div`
 flex: 1;
 overflow: hidden;
 padding-right: 24px;

 @media (max-width: ${breakpoints.tablet + 'px'}) {
  flex-direction: column;
  padding-right: 0;
 }

 .swiper-pagination-bullet {
  background-color: ${p => (p.lightBullets ? typoLightGray : tychoNavy)};
 }
`

const AnchorMenu = styled.div`
 background: white;
 border-bottom: 1px solid ${borderGray};
 height: 49px;
 max-width: ${maxWidth - 96}px;
 margin: auto;
 width: 100%;
 display: flex;

 @media (max-width: 1716px) {
  width: calc(100% - 96px);
 }

 > a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
 }

 > a:first-child {
  padding-right: 6px;
  padding-left: 6px;
 }
`

const AnchorMenuDesktop = styled.div`
 position: fixed;
 margin-top: 0;
 z-index: 99999;
 left: 0;
 right: 0;

 @media (max-width: ${breakpoints.tablet + 'px'}) {
  display: none;
 }
`

const ProductPage = ({ pageContext: { product, lang, germany } }) => {
 const location = useLocation()
 const hasPura = location.pathname.includes('pura')

 const data = useStaticQuery(graphql`
  query Product2 {
   allWcProducts {
    edges {
     node {
      wordpress_id
      name
      short_description
      slug
      price
      tags {
       name
      }
      acf {
       bullet_1
       bullet_2
       bullet_3
       euro_price
      }
      sku
      images {
       localFile {
        absolutePath
        childImageSharp {
         fluid(maxWidth: 1290, maxHeight: 840) {
          ...GatsbyImageSharpFluid
         }
        }
       }
      }
     }
    }
   }
  }
 `)

 SwiperCore.use([Pagination])

 const current = (edges, id = product.node.wordpress_id) =>
  id ? edges.node.wordpress_id === id : edges

 const series = () => {
  const categories = product.node.categories
  if (categories.some(elem => elem.name === 'Seria S – przesuwne')) {
   return ['Seria S', 'filter=s-series']
  }
  if (categories.some(elem => elem.name === 'Seria G – przesuwne do szkła')) {
   return ['Seria G', 'filter=g-series']
  }
  if (categories.some(elem => elem.name === 'Seria Loft – przesuwne')) {
   return ['Seria Loft', 'filter=tycho-loft']
  }
  if (categories.some(elem => elem.name === 'Seria F – składane')) {
   return ['Seria F', 'filter=f-series']
  }
  if (categories.some(elem => elem.name === 'Seria W – przesuwne')) {
   return ['Seria W', 'filter=w-series']
  }
  if (categories.some(elem => elem.name === 'Seria L – przesuwne lekkie')) {
   return ['Seria L', 'filter=l-series']
  }
 }

 const [activeTab, setActiveTab] = useState('id')
 const offset = 160
 const anchorSection = 'anchorSection'

 const highlightTab = () => {
  const sections = Array.from(document.getElementsByClassName(anchorSection))
  const sectionsBelowTop = sections => {
   return sections.getBoundingClientRect().top > 10
  }
  const filtered = sections.filter(sectionsBelowTop)
  var closestToTop = filtered[0] || sections[sections.length - 1]
  setActiveTab(closestToTop.id)
 }

 useEffect(() => {
  window.addEventListener('scroll', highlightTab)
  return () => {
   window.removeEventListener('scroll', highlightTab)
  }
 })

 const notEmptySection = tab => tab !== '' && tab !== null

 const { t, i18n } = useTranslation()
 useEffect(() => {
  i18n.changeLanguage(lang)
 }, [lang, i18n])
 // Set the language for SSR
 if (typeof window === 'undefined') {
  i18n.changeLanguage(lang)
 }

 const slug = name => {
  return slugify(t(`product::${name}`), { lower: true }).replace(
   /[^a-z0-9-]/gi,
   ''
  )
 }

 const prefix = germany ? lang + '/' : ''

 return (
  <Layout>
   <Helmet title={t(`product::${product.node.name}`)} />
   {/* Google Gtag view_item */}
   {/* <Helmet>
        <script type="text/javascript">
          {`gtag('event', 'view_item', {
        });
      `}
        </script>
      </Helmet> */}

   <Wrapper>
    <AnchorMenuDesktop id="anchor_menu_desktop">
     <AnchorMenu>
      <AnchorLink offset={offset} active={activeTab === 'id'} href={'#id'}>
       <TopIcon active={activeTab === 'id'} />
      </AnchorLink>
      {Content(product.node)
       .filter(notEmptySection)
       .map((tab, index) => (
        <AnchorLink
         offset={offset}
         active={activeTab === 'id' + index}
         href={'#id' + index}
         key={index}
        >
         {tab.sectionTitle}
        </AnchorLink>
       ))}
     </AnchorMenu>
    </AnchorMenuDesktop>

    <SectionWrapper className={anchorSection} id={'id'}>
     <MainProductPanel>
      <SwiperWrapper lightBullets={hasPura}>
       <BreadcrumbsTycho className="text">
        <Link to="/"> {t('Strona Główna')} </Link>
        <Link to={`/${prefix}${slug('Produkty')}/`}>{t('Produkty')}</Link>
        {series() && (
         <Link to={`/${prefix}${slug('Produkty')}/?${series()[1]}`}>
          {t(series()[0])}{' '}
         </Link>
        )}
        <span>{t(`product::${product.node.name}`)}</span>
       </BreadcrumbsTycho>
       <Swiper pagination={{ clickable: true }}>
        {data.allWcProducts.edges
         .filter(edges => current(edges))[0]
         .node.images.slice(0)
         .map((image, index) => (
          <SwiperSlide key={index}>
           {image.localFile && (
            <Img fluid={image.localFile.childImageSharp.fluid} />
           )}
          </SwiperSlide>
         ))}
       </Swiper>
      </SwiperWrapper>
      <div className="ConfiguratorWrapper">
       <Sku product={product} className="text sku" />
       <ConfiguratorWrapper product={product} />
      </div>
     </MainProductPanel>
    </SectionWrapper>

    {Content(product.node)
     .filter(notEmptySection)
     .map((tab, index) => (
      <SectionWrapper className={anchorSection} id={'id' + index} key={index}>
       <Section
        title={tab.sectionTitle}
        content={tab.sectionContent}
        open={index === 0}
       />
      </SectionWrapper>
     ))}
   </Wrapper>

   {product.node.upsell_ids.length !== 0 && (
    <div style={{ marginTop: 10 }}>
     <Wrapper>
      <SectionTitle> {t('Może Ci się spodobać')} </SectionTitle>
      <Line />
     </Wrapper>
     <Wrapper productList>
      <YouMayAlsoLike data={data} product={product} />
     </Wrapper>
    </div>
   )}
  </Layout>
 )
}

export default ProductPage
